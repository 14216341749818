<template>
  <div>
    <error-alert
      :error="error"
    />
    <portal to="body-top">
      <customer-name title="" />
    </portal>
    <l-table
      ref="refAttachmentTable"
      class="attachment-list"
      :module-name="MODULE_NAME"
      :table-columns="tableColumns"
      trash-list-path="home-customers-attachment-trash"
      :table-config-options="{
        endpoint: `${MODULE_NAME}/getActiveAttachment`
      }"
      :can-create="ACCESS_ABILITY_FOR_ATTACHMENTS"
    >
      <template #cell(actions)="{ data }">
        <div class="text-nowrap">
          <Can
            :i="ACCESS_ABILITY_FOR_ATTACHMENTS.action"
            :a="ACCESS_ABILITY_FOR_ATTACHMENTS.subject"
          >
            <feather-icon
              icon="LTrashIconKits"
              size="32"
              class="cursor-pointer mr-1"
              @click="moveToTrash(data.item)"
            />
            <feather-icon
              icon="LStockEyeIcon"
              size="32"
              class="cursor-pointer"
              @click="showAttachment(data.item)"
            />
          </Can>
        </div>
      </template>

      <template #cell(created_at)="{ data }">
        {{ new Date(data.value).toLocaleString('en-US', dateOptions) }}
      </template>
      <template #cell(attachment_category)="{ data }">
        {{ data.value + checkDocCategory(data.item.doc_category) }}
      </template>
      <template #filter>
        <Can
          :i="ACCESS_ABILITY_FOR_ATTACHMENTS.action"
          :a="ACCESS_ABILITY_FOR_ATTACHMENTS.subject"
        >
          <feather-icon
            icon="LTrashOpenIcon"
            size="30"
            style="padding: 9px; min-width: 35px; min-height: 35px"
            class="mr-1 featherIcon cursor-pointer border-dotted"
            @click="gotoNewPage({ name: 'home-customers-attachment-trash' }, $event)"
          />
          <feather-icon
            icon="LAddButtonIcon"
            class="cursor-pointer"
            size="36"
            @click="triggerFileInput"
          />
        </Can>
      </template>
    </l-table>

    <l-file-upload
      ref="refAttachmentFileUpload"
      v-model="chosenFiles"
      :is-editable="ACCESS_ABILITY_FOR_ATTACHMENTS"
      :accept-extension-array="['.jpg', '.jpeg', '.png', '.tif', '.pdf', '.bmp']"
      @input="showModalToChoseCategory"
    />

    <b-modal
      id="attachment-create-modal"
      centered
      hide-header
      hide-footer
      body-class="l-modal__body"
      footer-class="l-modal-footer"
      ok-title="Upload"
      ok-variant="success"
      :ok-disabled="isOkUploadBtnActive"
      :cancel-disabled="isCancelUploadBtnActive"
      cancel-variant="outline-primary"
      @hidden="clearData"
      @ok="uploadFile"
    >
      <b-overlay
        :show="isUploading"
      >
        <div class="l-modal__container p-2">
          <b-row>
            <b-col cols="12">
              <h3
                class="l-modal__title"
                style="font-size: 24px"
              > {{ $t('How should this file be categorized?') }}
              </h3>
            </b-col>
            <b-col>
              <p class="text-center">
                {{ $t('Please mind that you can only upload files up to 4MB each.') }}
              </p>
              <label class="ml-1">Category</label>
              <l-select
                v-model="sendData.attachment_category_id"
                name="attachment_category_id "
                :field="{options: {transition: '',},
                         store:'attachmentCategoryListContact'}"
              />
              <l-select
                v-if="sendData.attachment_category_id && sendData.attachment_category_id.id === 0"
                v-model="sendData.attachment_purchase_link"
                name="purchaseorder "
                :field="{options: {transition: '',},
                         store:''}"
              />
            </b-col>
          </b-row>
        </div>
      </b-overlay>
      <b-row class="text-center">
        <b-col
          cols="6"
          class="text-left"
        >
          <b-button
            variant="outline-primary"
            :disabled="isCancelUploadBtnActive"
            class="font-medium-1 font-weight-bolder text-dark align-center mb-2 pl-3 pr-3 ml-2"
            @click="hideModalToChoseCategory"
          >
            <feather-icon
              icon="LCancelIcon"
              size="16"
              class="align-center"
            /> {{ $t('Cancel') }}
          </b-button>
        </b-col>
        <b-col
          cols="6"
          class="text-right"
        >
          <b-button
            variant="success"
            class="font-medium-1 font-weight-bolder align-center mb-2 pl-3 pr-3 mr-2"
            :disabled="isOkUploadBtnActive"
            @click="uploadFile"
          >{{ $t('Upload') }}
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      id="attachment-move-to-trash-modal"
      centered
      hide-header
      hide-footer
      body-class="l-modal__body"
      footer-class="l-modal-footer"
      :ok-disabled="isOkTrashBtnActive"
      :cancel-disabled="isCancelTrashBtnActive"
    >
      <div class="l-modal__container">
        <b-row>
          <b-col cols="12">
            <h3 class="l-modal__title">
              Are you sure you want to send this attachment to the trash?
            </h3>
          </b-col>
        </b-row>
      </div>
      <b-row class="text-center">
        <b-col cols="6">
          <b-button
            variant="outline-primary"
            :disabled="isCancelTrashBtnActive"
            class="font-medium-1 font-weight-bolder text-dark align-center mb-2 pl-3 pr-3"
            @click="hideModalMoveToTrash"
          >
            <feather-icon
              icon="LCancelIcon"
              size="16"
              class="align-center"
            /> {{ $t('Cancel') }}
          </b-button>
        </b-col>
        <b-col cols="6">
          <b-button
            variant="success"
            class="font-medium-1 font-weight-bolder align-center mb-2 pl-3 pr-3"
            :disabled="isOkTrashBtnActive"
            @click="postMoveToTrash"
          >
            <feather-icon
              icon="LTrashIcon"
              size="14"
              class="align-center"
            /> {{ $t('Trash') }}
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <portal to="body-footer">
      <div class="d-flex mt-2 pb-1 justify-content-between">
        <div>
          <b-button
            class="cancelBtn font-medium-1 font-weight-bolder"
            variant="outline-primary"
            @click="backToList"
          >
            {{ $t('Back to List') }}
          </b-button>
        </div>
      </div>
    </portal>

  </div>
</template>

<script>

import {
  BModal, BRow, BCol, BOverlay, BButton,
} from 'bootstrap-vue'
import LTable from '@/views/components/LTable/LTable.vue'
import lFileUpload from '@/views/main/customers/view/attachments/LFileUpload.vue'
import LSelect from '@/views/components/DynamicForm/components/LSelect.vue'
import config from '@/views/main/customers/view/attachments/config'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ButtonDashed from '@/@core/components/b-button-dashed/ButtonDashed.vue'
import i18n from '@/libs/i18n'
import { useToast } from 'vue-toastification/composition'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import CustomerName from '../../../components/CustomerName'

export default {
  name: 'AttachmentsList',
  components: {
    ErrorAlert,
    LTable,
    lFileUpload,
    LSelect,
    BModal,
    BButton,
    BRow,
    BCol,
    BOverlay,
    ButtonDashed,
    CustomerName,
  },
  data() {
    return {
      isUploading: false,
      isMovingToTrash: false,
      inputValue: null,
      chosenFiles: null,
      trashItemId: null,
      error: {},
      allowedFormats: ['jpg', 'jpeg', 'png', 'tif', 'pdf', 'bmp'],
      sendData: {
        attachment_category_id: null,
        attachment_purchase_link: null,
        file: null,
        model_type: 0,
        model_id: this.$store.state.customers?.customer?.id,
      },
    }
  },
  computed: {
    isOkUploadBtnActive() {
      return this.isUploading || !this.sendData.attachment_category_id
    },
    isCancelUploadBtnActive() {
      return this.isUploading
    },
    isOkTrashBtnActive() {
      return this.isMovingToTrash
    },
    isCancelTrashBtnActive() {
      return this.isMovingToTrash
    },
    getAllAttachments() {
      return this.$store.state[this.MODULE_NAME].attachments
    },
  },
  created() {
    this.$emit('updateMenu', 'home-customers-attachments')
  },
  methods: {
    checkDocCategory(category) {
      let theValue = ''
      if (category !== 'Undefined') {
        theValue = ` - ${category.charAt(0).toUpperCase()}${category.slice(1)}`
      }
      return theValue
    },
    backToList() {
      this.$router.push({ name: 'home-customers-list' })
    },
    showModalToChoseCategory() {
      this.$bvModal.show('attachment-create-modal')
    },
    hideModalToChoseCategory() {
      this.$bvModal.hide('attachment-create-modal')
    },
    showModalMoveToTrash() {
      this.$bvModal.show('attachment-move-to-trash-modal')
    },
    hideModalMoveToTrash() {
      this.$bvModal.hide('attachment-move-to-trash-modal')
    },
    triggerFileInput() {
      this.$refs.refAttachmentFileUpload.clickFileInput()
    },

    clearData() {
      this.sendData.attachment_category_id = null
      this.sendData.attachment_purchase_link = null
      this.sendData.file = null
      this.chosenFiles = null
      this.$refs.refAttachmentFileUpload.clearInputValue()
    },

    showAttachment({ url }) {
      window.open(url, '_blank')
    },

    mapDataObject() {
      return {
        ...this.sendData,
        attachment_category_id: this.sendData.attachment_category_id.id,
        file: this.chosenFiles[0],
      }
    },
    convertToFormData(mappedDataObject) {
      const formData = new FormData()
      Object.entries(mappedDataObject)
        .forEach(([itemKey, itemValue]) => {
          formData.append(itemKey, itemValue)
        })
      return formData
    },
    postData(formData) {
      this.$store.dispatch(`${this.MODULE_NAME}/create`, formData)
        .catch(err => {
          console.log('message error: ', err)
          this.error = err.response?.data
        })
        // .catch(() => {
        //   this.$toast({
        //     component: ToastificationContent,
        //     props: {
        //       title: i18n.t('Error fetching attachments list', { module: this.MODULE_NAME }),
        //       icon: 'AlertTriangleIcon',
        //       variant: 'danger',
        //     },
        //   })
        // })
        .finally(() => {
          this.hideModalToChoseCategory()
          this.$refs.refAttachmentTable.refetchData()
          this.isUploading = false
        })
    },
    checkTheFileType(file) {
      const fileType = file.split('/')[1]
      let isErrorAvailable = false
      if (!this.allowedFormats.includes(fileType)) {
        isErrorAvailable = true
        this.$bvToast.toast(this.$t('File format is not accepted'), {
          variant: 'danger',
          solid: true,
          title: this.$t('Error'),
        })
        this.hideModalToChoseCategory()
        this.isUploading = false
      }
      return isErrorAvailable
    },
    uploadFile(bvModalEvent) {
      bvModalEvent.preventDefault()
      console.log('convertToFormData', this.chosenFiles)
      const file = this.chosenFiles[0]
      const newUploadedDocID = Number(this.sendData.attachment_category_id.id)
      const mappedData = this.getAllAttachments.filter(item => item.active === true).map(item => Number(item.attachment_category_id))
      const exists = mappedData.includes(newUploadedDocID)
      if (this.checkTheFileType(file.type)) {
        return
      }
      if (exists) {
        this.hideModalToChoseCategory()
        this.isUploading = false
        this.$bvToast.toast(this.$t('There is an attachment already with the same category'), {
          variant: 'danger',
          solid: true,
          title: this.$t('Error'),
        })
        return
      }
      if (file.size > 4 * 1024 * 1024) {
        this.hideModalToChoseCategory()
        this.isUploading = false
        // 4MB limit
        this.$bvToast.toast(this.$t('File size exceeds the limit of 4MB. Please choose a smaller file'), {
          variant: 'danger',
          solid: true,
          title: this.$t('Error'),
        })
        return
      }

      this.isUploading = true
      const formData = this.convertToFormData(this.mapDataObject())
      this.postData(formData)
    },

    moveToTrash({ id }) {
      this.showModalMoveToTrash()
      this.trashItemId = id
    },
    postMoveToTrash(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.isMovingToTrash = true
      this.$store.dispatch(`${this.MODULE_NAME}/move-to-trash`, { id: this.trashItemId })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: i18n.t('Error fetching {module} list', { module: this.MODULE_NAME }),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.hideModalMoveToTrash()
          this.$refs.refAttachmentTable.refetchData()
          this.isMovingToTrash = false
        })
    },
  },
  setup() {
    const {
      tableColumns,
      dateOptions,
      MODULE_NAME,
      ACCESS_ABILITY_FOR_ATTACHMENTS,
    } = config()
    const toast = useToast()

    return {
      toast,
      dateOptions,
      tableColumns,
      MODULE_NAME,
      ACCESS_ABILITY_FOR_ATTACHMENTS,
    }
  },
}
</script>
<style>
.attachment-list{
  margin-bottom: 150px;
}
</style>
